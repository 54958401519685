import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../interceptor";

export const getNotificationsAction = createAsyncThunk("getNotifications", async ({ page, pageSize }) => {
    try {
        const result = await axiosInstance.get(`Notification?Page=${page}&PageSize=${pageSize}`);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const addNotificationAction = createAsyncThunk("addNotifications", async (notification) => {
    try {
        console.log(notification);
        const result = await axiosInstance.post("Notification", notification);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});



const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: { notifications: [], isLoading: true, isError: false },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
            state.notifications = action.payload;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(addNotificationAction.rejected, (state, action) => {
            state.isError = true;
        });
    }
});

export default notificationsSlice.reducer;