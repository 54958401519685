import React, { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import classes from '../styles/reportDetails.module.css';
import { ThemeContext } from '../Context/ThemeContext';
import Btn from '../components/Buttons/Btn';
import Loader from '../components/Spinner/Loader';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, assignMissionToRanger, cancelMission, getReportDetailsAction, setReportDetailsNull } from '../redux/slices/reportDetailsSlice';
import MapComponent from '../components/Map/Map';
import { useTranslation } from 'react-i18next';

const ModalBody = lazy(() => import('../components/Modal/Modal'));
const MovementsModal = lazy(() => import('../components/Modal/MovementsModal'));

const ReportDetails = () => {

    const location = useLocation();
    const { theme } = useContext(ThemeContext);
    const [toggleAssign, setToggleAssign] = useState(false);
    const [toggleClose, setToggleClose] = useState(false);
    const [toggleShow, setToggleShow] = useState(false);
    const [toggleComment, setToggleComment] = useState(false);
    const [comment, setComment] = useState("");
    const [idx, setIdx] = useState(null);
    const reportDetails = useSelector(state => state.reportDetails.reportDetails);
    const isLoading = useSelector(state => state.reportDetails.isLoading);
    const isAssignLoading = useSelector(state => state.reportDetails.isAssignLoading);
    const isError = useSelector(state => state.reportDetails.isError);
    const me = useSelector(state => state.currentUser.currentUser);
    const base_url = "https://storage-baboon.bhr.sa/api/";
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const language = localStorage.getItem('language') || 'ar'

    const { id } = location.state || 1;

    useEffect(() => {
        dispatch(setReportDetailsNull());
    }, []);

    useEffect(() => {
        dispatch(getReportDetailsAction(id));
    }, [dispatch, id]);


    const handleAssign = async (done, rangerId) => {
        setToggleAssign(false);
        if (done === 'yes') {
            await dispatch(assignMissionToRanger({ id: reportDetails?.mission?.id, rangerId }));
            await dispatch(getReportDetailsAction(id));
        }
    };

    const handleClose = (done) => {
        setToggleClose(false);
        if (done === 'yes') dispatch(cancelMission(reportDetails?.mission?.id));
    };

    const handleComment = async (done, content) => {
        if (done === 'yes') {
            await dispatch(addComment({ id: reportDetails.mission.id, content }));
            await dispatch(getReportDetailsAction(id));
        }
        setComment(null);
        setToggleComment(false);
    };

    const handleShow = () => {
        setToggleShow(false);
    }

    const relativeDate = useCallback((dateInput) => {
        let date = new Date(dateInput);
        let now = new Date();
        date.setHours(date.getHours() + 3);
        let diff = now - date;

        let diffSeconds = Math.floor(diff / 1000);
        let diffMinutes = Math.floor(diffSeconds / 60);
        let diffHours = Math.floor(diffMinutes / 60);
        let diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) return diffDays === 1 ? `${t('Since')} ${diffDays} ${t('Day')}` : `${t('Since')} ${diffDays} ${t('Days')}`;
        if (diffHours > 0) return diffHours === 1 ? `${t('Since')} ${diffHours} ${t('Hour')}` : `${t('Since')} ${diffHours} ${t('Hours')}`;
        if (diffMinutes > 0) return diffMinutes === 1 ? `${t('Since')} ${diffMinutes} ${t('Minute')}` : `${t('Since')} ${diffMinutes} ${t('Minutes')}`;
        return diffSeconds === 1 ? `${t('Since')} ${diffSeconds} ${t('Second')}` : `${t('Since')} ${diffSeconds} ${t('Seconds')}`;
    }, [t]);

    const handleOpenGoogleMaps = (lat, lng) => {
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        window.open(googleMapsUrl, '_blank');
    };

    const convertDate = useCallback((dateInput) => {
        let date = new Date(dateInput);
        return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
    }, []);

    const convertTime = useCallback((dateInput) => {
        let date = new Date(dateInput);
        date.setHours(date.getHours() + 3);

        let hours = date.getHours() % 12 || 12;
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let period = date.getHours() >= 12 ? t('PM') : t('AM');

        return `${hours}:${minutes} ${period}`;
    }, [t]);

    if (isError) return <div>Error...</div>
    if (isLoading || isAssignLoading) return <Loader />
    return (
        <div className='main_content'>
            <div className={classes.header}>
                <div className={classes.header_details}>
                    <div>
                        <h1 className={`text_${theme} `}>
                            {t('Mission Number')}  ({reportDetails.mission.id})
                        </h1>
                    </div>
                    <p className={classes.header_new_report}>{location.state.status}</p>
                </div>
                <div className={classes.header_time}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    {language === 'en' ? <img src="/icons/i.png" alt="" /> : null}
                        <p style={{ marginLeft: '10px' }} >{relativeDate(reportDetails.mission.reportedDate)}</p>
                        {language === 'ar' ? <img src="/icons/i.png" alt="" /> : null}
                    </div>
                </div>

            </div>

            <div className={classes.body}>

                <div className={classes.right}>
                    <h2 className={`text_${theme}`}>
                        {t('Incident Details')}
                    </h2>

                    <div className={classes.section}>
                        <h3 className={`text_${theme}`}>
                            {t('Monkeys Number')}
                        </h3>
                        <input type="text" disabled value={reportDetails.count} name="" id="" />
                    </div>
                    {reportDetails?.reasons?.length > 0 ?
                        <div className={classes.section}>
                            <h3 className={`text_${theme}`}>
                                {t('Monkeys Status')}
                            </h3>

                            <div className={classes.monkey_states}>
                                {reportDetails?.reasons?.map((s) => {
                                    return <div key={s.id} className={classes.state}>
                                        {s.name}
                                    </div>
                                })}
                            </div>
                        </div> : null}

                    {reportDetails.notes ?
                        <div className={classes.section}>
                            <h3 className={`text_${theme}`}>
                                {t('Note')}
                            </h3>
                            <textarea disabled value={reportDetails.notes}></textarea>
                        </div>
                        : null}
                    {reportDetails.images?.length > 0 ?
                        <div className={classes.section}>
                            <h3 className={`text_${theme}`}>
                                {t('Monkeys Images')}
                            </h3>

                            <div className={classes.monkey_img}>
                                {reportDetails.images.map((i, index) => {
                                    return <div key={index} className={classes.img}>
                                        <img src={`${base_url}${i}`} alt={`monkey ${index}`} />
                                    </div>
                                })}
                            </div>
                        </div>
                        : null}
                    <div className={classes.section}>
                        <h3 className={`text_${theme}`}>
                            {t('Region')}
                        </h3>
                        <input type="text" disabled value={reportDetails.region.name} name="" id="" />

                        <div className={classes.location}>

                            <Grid container width={'90%'} spacing={2}>
                                <Grid item xs={6} sm={11}>
                                    <Btn btnStatus={false} txt={t('Call')} txtColor={'#B65535'} img={'/contact.png'} />
                                </Grid>
                                <Grid item xs={6} sm={11}>
                                    <Btn btnStatus={false} txt={t('Directions')} txtColor={'#B65535'} Fn={() => handleOpenGoogleMaps(reportDetails.lat, reportDetails.lng)} />
                                </Grid>
                            </Grid>
                            <div className={classes.location_map}>
                                <MapComponent lat={reportDetails.lat} lng={reportDetails.lng} name={reportDetails.locationString} />
                            </div>
                        </div>
                    </div>
                </div>
                {reportDetails.mission.histories?.length > 0 ?
                    <div className={classes.left}>
                        <Suspense fallback={<Loader />}>
                            {toggleAssign && <ModalBody
                                open={toggleAssign}
                                handleClose={handleAssign}
                                title={t('Assign')}
                                subtitle={t('Assign Title')}
                                search={true}
                                rightBtnTxt={t('Assign')}
                                leftBtnTxt={t('Back')}
                                assign={true}
                            />}
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            {toggleClose &&
                                <ModalBody
                                    open={toggleClose}
                                    handleClose={handleClose}
                                    title={t('Close Incident')}
                                    subtitle={t('Close Subtitle')}
                                    rightBtnTxt={t('Close Button')}
                                    leftBtnTxt={t('Back')}
                                />
                            }
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            {toggleComment &&
                                <ModalBody
                                    open={toggleComment}
                                    handleClose={handleComment}
                                    title={t('Send Comment')}
                                    subtitle={t('Comment Subtitle')}
                                    fieldExist={true}
                                    placeholder={t('Comment Txt')}
                                    value={comment}
                                    handleChange={setComment}
                                    rightBtnTxt={t('Send')}
                                    leftBtnTxt={t('Cancel')}
                                />
                            }
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            {toggleShow &&
                                <MovementsModal
                                    open={toggleShow}
                                    handleClose={handleShow}
                                    title={t('Actions')}
                                    data={reportDetails?.mission?.histories[idx]}
                                    date={convertDate(reportDetails?.mission?.histories[idx]?.date)}
                                    time={convertTime(reportDetails?.mission?.histories[idx]?.date)}
                                    idx={idx}
                                    setIdx={setIdx}
                                    length={reportDetails?.mission?.histories?.length}
                                    comments={reportDetails?.mission?.notes}
                                    survey={reportDetails?.mission?.survey}
                                />
                            }
                        </Suspense>
                        <div className={classes.left_header}>
                            <h3 className={`text_${theme}`}>
                                {t('Actions')}
                            </h3>
                        </div>

                        <div className={classes.table_header}>
                            <div className={`text_${theme} ${classes.headings} ${classes.big_heading}`}>
                                {t('Date')}
                            </div>

                            <div className={`text_${theme} ${classes.headings} ${classes.big_heading}`}>
                                {t('Executed')}
                            </div>

                            <div className={`text_${theme} ${classes.headings} ${classes.big_heading}`}>
                                {t('Action')}
                            </div>

                            <div className={`text_${theme} ${classes.headings} ${classes.small_heading}`}>
                                {t('Details')}
                            </div>
                        </div>

                        {reportDetails.mission.histories.map((d, index) => {
                            return (
                                <div key={d.id} className={classes.table_header}>
                                    <div className={`${classes.date_section} ${classes.big_heading}`}>
                                        <p className={` ${classes.table_txt} text_${theme}`}>{convertDate(d.date)}</p>
                                        <p className={` ${classes.table_txt} text_${theme}`}>{convertTime(d.date)}</p>
                                    </div>

                                    <div className={`${classes.user_section} ${classes.big_heading}`}>

                                        <div className={classes.user_section_right}>
                                            <img className={classes.user_image} src={'/user1.png'} alt="user" />
                                        </div>

                                        <div className={classes.user_section_left}>
                                            <p className={` ${classes.table_txt} text_${theme}`}>{d.eventBy?.roles[0] ?? ''}</p>
                                            <p className={` ${classes.table_txt} text_${theme}`}>{`${d.eventBy?.firstname ?? ''} ${d.eventBy?.lastname ?? ''}`}</p>
                                        </div>

                                    </div>

                                    <div className={`${classes.action_section} ${classes.big_heading}`}>
                                        <p className={` ${classes.table_txt} text_${theme}`}>
                                            {d.event}
                                        </p>
                                    </div>

                                    <div className={classes.details_section}>
                                        <button className={`text_${theme}`} onClick={() => { setIdx(index); setToggleShow(true); }}>{t('Display')}</button>
                                    </div>
                                </div>
                            )
                        })}

                        {me?.roles?.length > 0 ? <Grid container spacing={1} my={1}>
                            <Grid item xs={4}>
                                <Btn btnStatus={false} txt={t('Comment')} txtColor={'#B65535'} img={'/comment.png'} Fn={() => setToggleComment(true)} />
                            </Grid>
                            <Grid item xs={4}>
                                {me?.roles[0] === 'rangersManager' ?
                                    <Btn btnStatus={false} txt={t('Assign')} txtColor={'#B65535'} img={"/assign.png"} Fn={() => setToggleAssign(true)} /> :
                                    <Btn btnStatus={false} txt={t('Assign')} txtColor={'#B65535'} img={"/assign.png"} opacity={false} />}
                            </Grid>
                            <Grid item xs={4}>
                                {location?.state?.status === 'new' ?
                                    <Btn btnStatus={false} txt={t('Cancel')} txtColor={'#B65535'} img={"/action_log/reject.png"} Fn={() => me?.roles[0] === 'rangersManager' ? setToggleClose(true) : null} opacity={me?.roles[0] === 'rangersManager' ? true : false} /> :
                                    <Btn btnStatus={false} txt={t('Cancel')} txtColor={'#B65535'} img={"/action_log/reject.png"} opacity={false} />}
                            </Grid>
                        </Grid> : null}
                    </div> : null}
            </div>

        </div>
    );
}

export default ReportDetails;
